<template>
  <v-card>
    <v-card-text class="py-0">
      <!-- Data Table -->
      <v-col cols="12">
        <v-card flat>
          <v-data-table
            :headers="headers"
            :items="conceptosADevengarXPeriodo"
            v-model="selected"
            show-select
            :hide-default-footer="conceptosADevengarXPeriodo.length === 1"
            item-key="adiPorPeriId"
            class="elevation-1"
            @toggle-select-all="selectAllToggle"
          >
            <template v-slot:[`item.importe`]="{ item }">
              <span>{{ addDecimals(item.importe) }}</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-card-title class="headline primary--text"
                      >Conceptos a devengar por período</v-card-title
                    >
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template
              v-slot:[`item.data-table-select`]="{ isSelected, select }"
            >
              <v-simple-checkbox
                :value="isSelected"
                color="primary"
                @input="select($event)"
                :ripple="false"
              ></v-simple-checkbox>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-form
        v-model="isFormValid"
        ref="form"
        form="form"
        id="form"
        @submit.prevent=""
        v-if="selected.length > 0"
      >
        <v-row class="pl-3">
          <v-col cols="12" sm="12" md="3" class="py-0">
            <v-text-field
              type="text"
              outlined
              dense
              label="Porcentaje"
              hint="Porcentaje de actualización"
              v-model="porcentajeActualizacion"
              :rules="
                rules.nonZero.concat(
                  rules.required,
                  [porcentajeActualizacion >= -100 || 'Formato inválido'],
                  porcentajeActualizacion > 0
                    ? rules.maxLength(porcentajeActualizacion, 6)
                    : rules.maxLength(porcentajeActualizacion, 7)
                )
              "
              :append-icon="porcentajeIcon"
              onkeypress="return (event.charCode >= 45 && event.charCode <= 57)"
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="4" class="py-0">
            <v-autocomplete
              v-model="redondeoSelected"
              :items="redondeos"
              label="Redondeo"
              item-text="value"
              item-value="value"
              outlined
              clearable
              dense
              :rules="rules.required"
            >
            </v-autocomplete>
          </v-col> -->
        </v-row>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined class="to-right" @click="closeModal"> Cerrar </v-btn>
        <v-btn
          color="primary"
          @click="completarRegistros()"
          class="to-right"
          :disabled="!selected.length > 0 || !isFormValid"
        >
          Registrar
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import getFormatPrice from "@/utils/helpers/getFormatPrice";

export default {
  name: "BuscarConceptosModal",
  directives: { mask },
  props: {
    conceptosADevengarXPeriodo: {
      type: Array,
      required: true,
    },
    vigencia: {
      type: String,
      required: true,
    },
  },
  data: (vm) => ({
    rules: rules,
    porcentajeIcon: enums.icons.PERCENT_OUTLINE,
    headers: [
      {
        text: "Agente de cuenta",
        value: "agectaNom",
        sortable: false,
      },
      {
        text: "N° de grupo familiar",
        value: "benGrId",
        sortable: false,
      },
      {
        text: "Concepto",
        sortable: false,
        value: "conceptoNom",
      },
      {
        text: "Importe",
        sortable: false,
        align: "end",
        value: "importe",
      },
      {
        text: "Período desde",
        sortable: false,
        value: "periDesde",
      },
      {
        text: "Período hasta",
        sortable: false,
        value: "periHasta",
      },
    ],
    selected: [],
    isFormValid: false,
    redondeos: [
      { id: 1, value: "Unidad" },
      { id: 2, value: "Decena" },
      { id: 3, value: "Centena" },
    ],
    redondeoSelected: null,
    porcentajeActualizacion: null,
  }),
  created() {
    this.selectAllToggle();
  },
  methods: {
    ...mapActions({
      completarActualizacionConceptosXPeriodo:
        "devengamientos/completarActualizacionConceptosXPeriodo",
      setAlert: "user/setAlert",
    }),
    formatPrice(importe) {
      const formatPrice = new Intl.NumberFormat("es-AR");
      const importeFormatted = formatPrice.format(importe);
      return importeFormatted;
    },
    closeModal() {
      this.$emit("toggleModalBuscarRegistros");
    },
    selectAllToggle() {
      if (this.selected.length != this.conceptosADevengarXPeriodo.length) {
        this.selected = [];
        this.selected = this.conceptosADevengarXPeriodo;
        this.setAlert({
          type: "info",
          message: "Todos los conceptos a devengar han sido seleccionados.",
        });
      } else {
        this.selected = [];
        this.setAlert({
          type: "info",
          message: "Todos los conceptos a devengar han sido deseleccionados.",
        });
      }
    },
    async completarRegistros() {
      let datosProcesoActualizacion = [];
      this.selected.forEach((x) => {
        const dato = {
          adiPorPeriId: x.adiPorPeriId,
          agenteCuentaId: x.agectaId,
          agenteCuentaNom: x.agectaNom,
          benGrId: x.benGrId,
          concepto: x.conceptoNom,
          periodoDesde: x.periDesde,
          periodoHasta: x.periHasta,
          nuevoPeriodoDesde: this.vigencia,
          importeAnterior: x.importe,
          porcentaje: this.porcentajeActualizacion,
        };
        datosProcesoActualizacion.push(dato);
      });
      const data = {
        procesoByFilters: true,
        datosProcesoActualizacion: datosProcesoActualizacion,
      };
      try {
        const registrosActualizados =
          await this.completarActualizacionConceptosXPeriodo(data);
        if (registrosActualizados.datosProcesoActualizacion.length > 0) {
          this.setAlert({
            type: "success",
            message: "Conceptos cargados con éxito.",
          });
          const datosProcesoActualizacion = {
            procesoByFilters: true,
            datos: registrosActualizados.datosProcesoActualizacion,
            nombreArchivo: "Actualización por búsqueda manual",
          };
          this.$emit("setRegistrosActualizados", datosProcesoActualizacion);
        }
        this.closeModal();
      } catch {}
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
  },
};
</script>

<style scoped></style>
