<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="11" sm="12" class="pr-0 pt-5 pb-0">
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row justify="end">
              <v-col
                col="9"
                class="pt-0 pb-0"
                align="start"
                align-self="center"
              >
                <v-btn-toggle dense v-model="text" color="primary" group>
                  <v-btn
                    value="BuscarRegistros"
                    @click="switchTab(1)"
                    :disabled="registrosActualizacion.length > 0"
                  >
                    Buscar registros
                  </v-btn>
                  <v-btn
                    value="ProcesarArcivo"
                    @click="switchTab(2)"
                    :disabled="registrosActualizacion.length > 0"
                  >
                    Procesar archivo
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col
                cols="1"
                align-self="center"
                align="center"
                class="pr-0 pl-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                align="left"
                class="pt-0 pb-0"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
            </v-row>

            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row v-if="!tabProcesoActualizacion">
                  <!-- Concepto -->
                  <v-col cols="4" class="py-0">
                    <v-autocomplete
                      v-model="conceptoSelected"
                      :items="conceptos"
                      label="Concepto"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Vigencia -->
                  <v-col cols="2" class="py-0">
                    <v-text-field
                      dense
                      outlined
                      v-model="vigencia"
                      label="Vigencia (*)"
                      type="text"
                      hint="Formato AAAAMM"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      persistent-hint
                      v-mask="'######'"
                      :rules="rules.required.concat(rules.periodoYyyyMm)"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="end" v-if="!tabProcesoActualizacion">
                  <v-col class="py-0" align="left">
                    <v-subheader>(*) Datos obligatorios</v-subheader>
                  </v-col>
                  <v-col cols="12" md="4" sm="6" class="pb-0 pt-5 text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      :disabled="
                        !isFormValid || registrosActualizacion.length > 0
                      "
                      elevation="2"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            item-key="adiPorPeriId"
            :items="registrosActualizacion"
            :loading="loading"
            class="elevation-1"
            :search="search"
            loading-text="Cargando datos..."
          >
            <template v-slot:[`item.agenteCuentaNom`]="{ item }">
              <span v-if="item.invalidImporte" class="invalidConfigText">
                {{ item.agenteCuentaNom }}</span
              >
              <span v-else> {{ item.agenteCuentaNom }} </span>
            </template>
            <template v-slot:[`item.benGrId`]="{ item }">
              <span v-if="item.invalidImporte" class="invalidConfigText">
                {{ item.benGrId }}</span
              >
              <span v-else> {{ item.benGrId }} </span>
            </template>
            <template v-slot:[`item.concepto`]="{ item }">
              <span v-if="item.invalidImporte" class="invalidConfigText">
                {{ item.concepto }}</span
              >
              <span v-else> {{ item.concepto }} </span>
            </template>
            <template v-slot:[`item.importeAnterior`]="{ item }">
              <span v-if="item.invalidImporte" class="invalidConfigText">
                {{ item.importeAnterior }}</span
              >
              <span v-else> {{ item.importeAnterior }} </span>
            </template>
            <template v-slot:[`item.importeActualizado`]="{ item }">
              <span v-if="item.invalidImporte" class="invalidConfigText">
                {{ item.importeActualizado }}</span
              >
              <span v-else> {{ item.importeActualizado }} </span>
            </template>
            <template v-slot:[`item.nuevoPeriodoDesde`]="{ item }">
              <span v-if="item.invalidImporte" class="invalidConfigText">
                {{ item.nuevoPeriodoDesde }}</span
              >
              <span v-else> {{ item.nuevoPeriodoDesde }} </span>
            </template>
            <template v-slot:[`item.nuevoPeriodoHasta`]="{ item }">
              <span v-if="item.invalidImporte" class="invalidConfigText">
                {{ item.nuevoPeriodoHasta }}</span
              >
              <span v-else> {{ item.nuevoPeriodoHasta }} </span>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="9">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" align="end">
                    <v-btn
                      color="primary"
                      small
                      class="no-upper-case to-right"
                      v-model="deleteAllConceptos"
                      :loading="loadingDeleteAllBtn"
                      @click="deleteAllRegistros()"
                      :disabled="
                        registrosActualizacion.length === 0 ||
                          loadingDeleteAllBtn
                      "
                    >
                      Eliminar todos
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="quitarConcepto(item.adiPorPeriId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Quitar concepto</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
        <v-row>
          <v-col cols="9" class="pl-5" v-if="disabledGenerarConceptosBtn">
            <p class="invalidConfigText">
              Los conceptos en color rojo superan el máximo de importe
              permitido. Eliminarlos de la lista para continuar.
            </p>
          </v-col>
          <v-col
            :cols="disabledGenerarConceptosBtn ? '3' : '12'"
            align="end"
            class="pl-0"
          >
            <v-btn
              color="primary"
              class="to-right"
              :disabled="
                registrosActualizacion.length === 0 ||
                  loadingGenerarConceptosBtn ||
                  disabledGenerarConceptosBtn
              "
              :loading="loadingGenerarConceptosBtn"
              @click="grabarActualizacionConceptos()"
            >
              Generar conceptos
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-if="modalBuscarRegistros"
      v-model="modalBuscarRegistros"
      max-width="80%"
      persistent
      @keydown.esc="toggleModalBuscarRegistros"
    >
      <BuscarConceptosModal
        :conceptosADevengarXPeriodo="conceptosADevengarXPeriodo"
        :vigencia="vigencia"
        @setRegistrosActualizados="setRegistrosActualizados($event)"
        @toggleModalBuscarRegistros="toggleModalBuscarRegistros"
      ></BuscarConceptosModal>
    </v-dialog>
    <v-dialog
      v-model="modalImportacionActualizacion"
      v-if="modalImportacionActualizacion"
      @keydown.esc="toggleModalImportacionActualizacion"
      max-width="70%"
      persistent
    >
      <NuevoImportacionActualizacion
        @setRegistrosActualizados="setRegistrosActualizados($event)"
        @toggleModalImportacionActualizacion="
          toggleModalImportacionActualizacion
        "
      ></NuevoImportacionActualizacion>
    </v-dialog>
    <v-dialog
      v-if="modalExportarExcel"
      v-model="modalExportarExcel"
      max-width="46%"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title>
            <span class="pl-1 primary--text"
              >Se generará un archivo con el detalle de los registros</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="to-right" text @click="exportExcel()">
              Continuar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <DeleteDialog
      :maxWidth="'25%'"
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmQuitarConcepto()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import FiltersSelected from "@/components/shared/FiltersSelected";
import DeleteDialog from "@/components/shared/DeleteDialog";
import helpersExcel from "@/utils/helpers/importExportExcel";
import GoBackBtn from "@/components/shared/GoBackBtn";
import BuscarConceptosModal from "@/components/modules/cuotas/devengamientos/BuscarConceptosModal.vue";
import NuevoImportacionActualizacion from "@/components/modules/cuotas/devengamientos/NuevoImportacionActualizacion.vue";

export default {
  name: "NuevoProcesoActualizacion",
  components: {
    PageHeader,
    DeleteDialog,
    FiltersSelected,
    GoBackBtn,
    BuscarConceptosModal,
    NuevoImportacionActualizacion
  },
  directives: { mask },
  data() {
    return {
      title: "Nuevo proceso de actualización",
      text: "BuscarRegistros",
      search: "",
      loading: false,
      loadingGenerarConceptosBtn: false,
      modalExportarExcel: false,
      loadingDeleteAllBtn: false,
      deleteAllConceptos: false,
      headers: [
        {
          text: "Agente de cuenta",
          value: "agenteCuentaNom",
          sortable: false
        },
        {
          text: "N° de grupo familiar",
          value: "benGrId",
          sortable: false
        },
        {
          text: "Concepto",
          sortable: false,
          value: "concepto"
        },
        {
          text: "Importe anterior",
          sortable: false,
          align: "end",
          value: "importeAnterior"
        },
        {
          text: "Importe actualizado",
          sortable: false,
          align: "end",
          value: "importeActualizado"
        },
        {
          text: "Período desde",
          sortable: false,
          value: "nuevoPeriodoDesde"
        },
        {
          text: "Período hasta",
          sortable: false,
          value: "nuevoPeriodoHasta"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      registrosActualizacion: [],
      showFiltersSelected: false,
      deleteIcon: enums.icons.DELETE,
      searchIcon: enums.icons.SEARCH,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      conceptos: [],
      conceptoSelected: null,
      routeToGo: "ProcesosActualizacion",
      isFormValid: false,
      showFilters: true,
      vigencia: null,
      showDeleteModal: false,
      filtersApplied: [],
      titleDelete: "¿Eliminar concepto?",
      rules: rules,
      deleteWidth: "25%",
      textDelete: "",
      tabBuscarRegistros: false,
      tabProcesoActualizacion: false,
      modalBuscarRegistros: false,
      conceptosADevengarXPeriodo: [],
      modalImportacionActualizacion: false,
      fileName: null,
      procesoByFilters: false,
      disabledGenerarConceptosBtn: false,
      resultsExportExcel: []
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setSelects();
  },
  methods: {
    ...mapActions({
      getConceptos: "devengamientos/getConceptos",
      grabarActualizacionConceptosXPeriodo:
        "devengamientos/grabarActualizacionConceptosXPeriodo",
      fetchConceptosADevengarByParamsToProceso:
        "devengamientos/fetchConceptosADevengarByParamsToProceso",
      setAlert: "user/setAlert"
    }),
    switchTab(num) {
      this.resetForm();
      this.filtersApplied = [];
      if (this.showFilters === false) this.showFilters = true;
      switch (num) {
        case 1:
          this.tabBuscarRegistros = true;
          this.tabProcesoActualizacion = false;
          break;
        case 2:
          this.tabProcesoActualizacion = true;
          this.modalImportacionActualizacion = true;
          break;
        default:
          break;
      }
    },
    async applyFilters() {
      this.loading = true;
      this.customizeFiltersApplied();
      const data = {
        conceptoId:
          this.conceptoSelected != null ? this.conceptoSelected.id : null,
        vigencia: this.vigencia
      };
      try {
        const response = await this.fetchConceptosADevengarByParamsToProceso(
          data
        );
        this.conceptosADevengarXPeriodo = response.conceptosDevengarPorPeriodo;
        this.modalBuscarRegistros = true;
        this.showFilters = false;
        this.showFiltersSelected = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async setSelects() {
      const conceptos = await this.getConceptos();
      this.conceptos = conceptos;
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = [];
      if (this.conceptoSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "conceptoSelected.value",
          label: "Concepto",
          model: this.conceptoSelected.value
        });
      }
      if (this.vigencia) {
        this.filtersApplied.splice(1, 1, {
          key: "vigencia",
          label: "Vigencia",
          model: this.vigencia
        });
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatPrice(importe) {
      const formatPrice = new Intl.NumberFormat("es-AR");
      const importeFormatted = formatPrice.format(importe);
      return importeFormatted;
    },
    closeOpenFilters() {
      this.showFiltersSelected = !this.showFiltersSelected;
      this.showFilters = !this.showFilters;
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    toggleModalBuscarRegistros() {
      this.modalBuscarRegistros = !this.modalBuscarRegistros;
      this.loading = false;
    },
    setRegistrosActualizados(registros) {
      let newId = 0;
      registros.datos.forEach(x => {
        if (x.adiPorPeriId === 0) {
          x.adiPorPeriId = newId;
          newId--;
        }
        if (x.importeToValid > 99999999.0 || x.importeToValid < -99999999.0) {
          this.disabledGenerarConceptosBtn = true;
          x.invalidImporte = true;
        }
      });
      this.fileName = registros.nombreArchivo;
      this.procesoByFilters = registros.procesoByFilters;
      this.registrosActualizacion = registros.datos.sort(x =>
        x.invalidImporte ? -1 : 1
      );
    },
    toggleModalImportacionActualizacion() {
      this.modalImportacionActualizacion = !this.modalImportacionActualizacion;
    },
    quitarConcepto(id) {
      this.titleDelete = "¿Quitar concepto?";
      this.deleteAllConceptos = false;
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    deleteAllRegistros() {
      this.deleteAllConceptos = true;
      this.titleDelete = "¿Quitar conceptos?";
      this.showDeleteModal = true;
    },
    async confirmQuitarConcepto() {
      if (this.deleteAllConceptos) {
        this.loadingDeleteAllBtn = true;
        this.showDeleteModal = false;
        this.setAlert({
          type: "success",
          message: "Todos los conceptos han sido eliminados con éxito."
        });
        this.registrosActualizacion = [];
        this.deleteAllConceptos = false;
        this.loadingDeleteAllBtn = false;
        this.disabledGenerarConceptosBtn = false;
      } else {
        this.registrosActualizacion = this.registrosActualizacion.filter(
          x => x.adiPorPeriId !== this.idToDelete
        );
        const findRegistroInvalido = this.registrosActualizacion.find(
          x => x.invalidImporte
        );
        if (findRegistroInvalido == null)
          this.disabledGenerarConceptosBtn = false;
        else this.disabledGenerarConceptosBtn = false;
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Quitado con éxito." });
      }
    },
    async grabarActualizacionConceptos() {
      this.loadingGenerarConceptosBtn = true;
      const data = {
        procesoByFilters: this.procesoByFilters,
        filterPeriodo: this.registrosActualizacion[0].nuevoPeriodoDesde,
        filterConcepto: this.registrosActualizacion[0].concepto,
        nombreArchivo: this.fileName,
        datosProcesoActualizacion: this.registrosActualizacion
      };
      const response = await this.grabarActualizacionConceptosXPeriodo(data);
      if (response.status === 200) {
        this.resultsExportExcel = response.data.data;
        this.modalExportarExcel = true;
        this.registrosActualizacion = [];
        this.setAlert({ type: "success", message: "Procesado con éxito." });
        this.loadingGenerarConceptosBtn = false;
      }
    },
    exportExcel() {
      let result = [];
      this.resultsExportExcel.forEach(x =>
        result.push({
          ["AgenteCuentaId"]: x.agenteCuentaId,
          ["AgenteCuentaNom"]: x.agenteCuentaNom,
          ["Periodo desde"]: x.nuevoPeriodoDesde,
          ["PeriodoHasta"]: x.nuevoPeriodoHasta,
          ["Concepto"]: x.concepto,
          ["Importe"]: x.importeActualizado,
          ["N° de grupo familiar"]: x.benGrId,
          ["Descripcion"]: x.descripcion
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Proceso actualización");
      this.toggleModalExportarExcel();
    },
    toggleModalExportarExcel() {
      this.modalExportarExcel = !this.modalExportarExcel;
      this.$router.push({
        name: "ProcesosActualizacion"
      });
    }
  }
};
</script>

<style scoped>
.no-upper-case {
  text-transform: none;
}
.fontColor {
  font-weight: bold !important;
  color: rgb(0, 0, 0) !important;
  text-align: center;
}
.invalidConfigText {
  color: red;
}
</style>
